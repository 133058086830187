exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-line-covers-index-tsx": () => import("./../../../src/pages/product-line/covers/index.tsx" /* webpackChunkName: "component---src-pages-product-line-covers-index-tsx" */),
  "component---src-pages-product-line-covers-liner-tsx": () => import("./../../../src/pages/product-line/covers/liner.tsx" /* webpackChunkName: "component---src-pages-product-line-covers-liner-tsx" */),
  "component---src-pages-product-line-covers-mpag-96-tsx": () => import("./../../../src/pages/product-line/covers/MPAG96.tsx" /* webpackChunkName: "component---src-pages-product-line-covers-mpag-96-tsx" */),
  "component---src-pages-product-line-covers-mplag-17-tsx": () => import("./../../../src/pages/product-line/covers/MPLAG17.tsx" /* webpackChunkName: "component---src-pages-product-line-covers-mplag-17-tsx" */),
  "component---src-pages-product-line-elements-adapters-tsx": () => import("./../../../src/pages/product-line/elements/adapters.tsx" /* webpackChunkName: "component---src-pages-product-line-elements-adapters-tsx" */),
  "component---src-pages-product-line-elements-branch-pipe-tsx": () => import("./../../../src/pages/product-line/elements/branch-pipe.tsx" /* webpackChunkName: "component---src-pages-product-line-elements-branch-pipe-tsx" */),
  "component---src-pages-product-line-elements-index-tsx": () => import("./../../../src/pages/product-line/elements/index.tsx" /* webpackChunkName: "component---src-pages-product-line-elements-index-tsx" */),
  "component---src-pages-product-line-index-tsx": () => import("./../../../src/pages/product-line/index.tsx" /* webpackChunkName: "component---src-pages-product-line-index-tsx" */),
  "component---src-pages-product-line-streamer-tsx": () => import("./../../../src/pages/product-line/streamer.tsx" /* webpackChunkName: "component---src-pages-product-line-streamer-tsx" */),
  "component---src-pages-technovazinc-jsx": () => import("./../../../src/pages/technovazinc.jsx" /* webpackChunkName: "component---src-pages-technovazinc-jsx" */),
  "component---src-templates-vacancies-city-name-tsx": () => import("./../../../src/templates/vacancies/[cityName].tsx" /* webpackChunkName: "component---src-templates-vacancies-city-name-tsx" */)
}

